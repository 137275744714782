<template>
  <div class="account">
    <el-row :gutter="20" class="account-container" type="flex">
      <el-col :span="4" class="account-col">
        <el-card class="box-card card">
          <div class="info">
            <div class="ava" @click="dialogVisible=true">
              <div class="modify">
                修改头像
              </div>
              <el-avatar class="el-avatar-my" :src="this.$store.state.user.avatar" :fit="fill">
              </el-avatar>
            </div>
            <div class="username">
              {{ this.$store.state.user.name }}
            </div>
          </div>
          <el-menu
              default-active="/account"
              class="menu"
              router>
            <el-menu-item index="/account">
              <i class="el-icon-setting"></i>
              <span slot="title">基本信息</span>
            </el-menu-item>
            <el-menu-item index="/account/modifyPassword">
              <i class="el-icon-setting"></i>
              <span slot="title">安全设置</span>
            </el-menu-item>
            <el-menu-item index="/account/faceAdd">
              <i class="el-icon-setting"></i>
              <span slot="title">人脸登录</span>
            </el-menu-item>
          </el-menu>
        </el-card>
      </el-col>
      <el-col :span="20" class="right">
        <router-view></router-view>
      </el-col>

      <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="40%"
          >
        <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>

        <span slot="footer" class="dialog-footer">
       <el-button @click="dialogVisible = false">取 消</el-button>
       <el-button type="primary" @click="modify">上传</el-button>
      </span>
      </el-dialog>

    </el-row>
  </div>
</template>

<script>
import global from "@/config/global";
import {isEmpty} from "@/utils/StringUtils";
import {updateImage} from "@/api/user";

export default {
  name: "index",
  data() {
    return {
      fill: "fill",
      dialogVisible: false,
      uploadUrl: global.uploadUrl,
      imageUrl: ""
    }
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.data.url;
      // this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt4M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt4M;
    },
    modify() {
      // console.log("修改头像")
      if (!isEmpty(this.imageUrl)) {
        updateImage(this.$store.state.user.id, this.imageUrl).then(result => {
          this.$message({
            type: 'success',
            message: result.data.user
          })
        }).catch(error => {
          this.$message({
            type: 'error',
            message: error.message
          })
        })
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: "请添加头像信息"
        })
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    }
  },


}
</script>

<style scoped>

/* 左边部分 */
.account {
  min-height: calc(100vh - 120px);
  width: 80%;
  margin: 10px auto;
  /*height: cal("");*/

}

.account-container {
  /*margin: 0 !important;*/
  /*background-color: #fff;*/
}

.right {
  background-color: #fff;
}

.account-col {
  margin: 0 !important;
}

.card {
  width: 100%;
}

.menu {
  /*border: 1px solid #eee;*/
  height: 100%;
  width: 100%;
  border: 0;
  min-height: calc(100vh - 120px);
}

/*  头像信息 */
.ava {
  min-height: 120px;
  display: flex;
  position: relative;
}

.modify {
  position: absolute;
  width: 80px;
  background: rgba(76, 172, 255, .7);
  height: 23px;
  line-height: 23px;
  top: 45px;
  text-align: center;
  left: 25%;
  color: #fff;
}

.el-avatar-my {
  margin: auto;
  height: 100px;
  width: 100px;

}

/* 用户信息*/
.info {
  width: 100%;
  min-height: 180px;
}

.username {
  height: 60px;
  width: 100%;
  line-height: 60px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
</style>